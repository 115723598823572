import React from "react"

const IFrameEmbed: React.FC = () => {
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>AI Interview Question Generator</title>
      <style>
        :root {
          --primary-color: #007bff;
          --secondary-color: #0056b3;
          --text-color: #333;
          --background-color: #f9f9f9;
          --button-hover: #004494;
        }

        *, *::before, *::after {
          box-sizing: border-box;
        }

        body {
          font-family: Arial, sans-serif;
          background-color: var(--background-color);
          margin: 0;
          padding: 10px;
        }

        .container {
          width: 100%;
          max-width: 800px;
          margin: auto;
          padding: 15px;
          background: white;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        h2 {
          color: var(--primary-color);
          text-align: center;
        }

        p {
          color: var(--text-color);
          text-align: center;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <h2>AI Interview Question Generator</h2>
        <p>Fill in the details below.</p>
      </div>
    </body>
    </html>
  `

    return (
        <iframe
            title="AI Interview Question Generator"
            srcDoc={htmlContent}
            style={{
                width: "100%",
                height: "500px",
                border: "none",
                borderRadius: "10px",
            }}
            sandbox="allow-scripts allow-same-origin"
        />
    )
}

export default IFrameEmbed
